@mixin versioned-setting-color($color) {
  border-left-color: $color;

  .type-badge {
    background-color: $color;
  }
}

@mixin versioned-setting($color) {
  > {
    .game-setting {
      @include versioned-setting-color($color);

      &:hover {
        @include versioned-setting-color(scale_color($color, $lightness: -20%));
      }
    }

    .expandable-setting-value {
      border-left: 1px solid rgba($color, $alpha: 0.3);
    }
  }
}

$versionSettingsType: (
  "string": $green,
  "object": $purple,
  "int": $blue,
  "double": $cyan,
  "array": $orange,
  "boolean": $yellow,
  "null": $pink,
);

$versionSettingsTypeBackground: (
  "object": map_get($versionSettingsType, "object"),
  "array": map_get($versionSettingsType, "array"),
);

@keyframes new-setting-fade {
  0% {
    background-color: lightyellow;
  }

  100% {
    background-color: $white;
  }
}

.top-level-add-button {
  position: absolute !important;
  right: 0;
  top: -60px;
}

#versioned-settings-container {
  position: relative;
}

.expandable-setting {
  width: 100%;
  @include versioned-setting($gray-600);

  @each $key, $color in $versionSettingsType {
    &.expandable-setting-#{$key} {
      @include versioned-setting($color);
    }
  }

  .game-setting {
    min-height: 56px;
    display: flex;
    background-color: $white;
    border: $border-width solid $gray-300;
    padding: 0.3rem 0.4rem;
    border-left: 2px solid;
    border-radius: $border-radius;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #b3b3b3;
    border-top-left-radius: 0;

    &.new-setting {
      animation: 5s linear new-setting-fade;
      animation-iteration-count: 1;
    }

    .caret {
      font-size: 1.4em;
      transition: transform 0.1s ease-in-out;
      margin: 0rem 0.5rem 0 0.3rem;

      &.expanded {
        transform: rotate(90deg);
      }
    }

    .left-part-container {
      margin-right: 0.5rem;
      min-width: 300px;
    }

    .heading-container,
    .tag-container {
      display: flex;
      overflow: hidden;

      .marquee {
        transform: translateX(0%);
      }

      &:hover .marquee {
        animation: example1 10s linear infinite;
      }

      .non-editable {
        white-space: nowrap;
      }

      .heading,
      .tag {
        padding: 0;
        height: 22px;
        transition: all 0.2s ease-in-out;

        .highlight {
          font-weight: 600;
          background: #ffff00;
          border-radius: 1px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
          padding: 2px;
        }

        &:disabled {
          pointer-events: none;
        }

        @keyframes example1 {
          0% {
            transform: translateX(0%);
          }
          50% {
            transform: translateX(-100%);
          }
          50.00000000000001% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
      }

      .heading {
        font-size: 0.9em;
        font-weight: 500;
      }

      .tag {
        font-size: 0.9em;
        color: $text-muted;
        font-weight: 300;

        &.only-show-on-hover {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
        }

        &.only-show-on-hover:hover,
        &.only-show-on-hover:focus {
          opacity: 100;
        }
      }
    }

    .value-container {
      min-width: 0;
    }

    .last-edited {
      line-height: 1.1;
      color: $gray-500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .badge-column {
      button {
        &:disabled {
          opacity: 1;
        }
      }
    }

    .type-badge {
      color: $white;
      font-weight: lighter;
      padding: 0.5rem;
      width: 90px;
    }

    .inline-menu,
    .inline-menu-input {
      svg {
        margin: 5px;
      }

      span {
        min-width: 100px;
      }
    }

    .inline-menu-input {
      input {
        width: 75px;
        padding: 2px 5px;
        height: 22px;
        transition: all 0.2s ease-in-out;
        background-color: transparent;
        border-color: transparent;

        &:disabled {
          pointer-events: none;
        }

        &:hover,
        &:focus {
          background-color: #fff;
        }
      }
    }

    .dropdown-group {
      .inline-edit-button {
        margin-right: 0.5rem;

        display: none;
        cursor: pointer;

        .inline-edit-icon {
          color: $text-muted;
          transition: color 0.1s ease-in-out;
        }

        svg {
          font-size: 1rem;
        }

        &:hover {
          svg {
            color: $gray-900;
          }
        }
      }

      .spacer {
        height: 40px;
        margin: 0 4px;
        border-right: $border-width solid $border-color;
      }

      .button-delete {
        color: $danger;

        &:active {
          color: $white;
          background-color: scale-color($danger, $lightness: -15%);
        }
      }
    }

    &:hover {
      background-color: $gray-200;

      .dropdown-group {
        .inline-edit-button {
          display: block;
        }
      }
    }

    @media only screen and (max-width: 701px) {
      flex-direction: column;
      overflow-wrap: break-word;
      align-items: start !important;
      overflow: hidden;

      position: relative;

      .heading {
        min-width: auto;
      }

      .heading-container {
        padding-bottom: 0.5rem;
      }

      .heading-container,
      .tag-container {
        width: auto !important;
      }

      .inline-edit-button {
        display: none !important;
      }

      .dropdown-group {
        width: 100%;
        justify-content: space-between !important;
      }

      .badge-column {
        button {
          padding: 0;
        }
        .badge {
          padding: 0.3rem;
        }
      }

      .caret {
        margin: 1rem;
      }

      .spacer {
        display: none;
      }

      .container-variable {
        padding-left: 0 !important;
      }
    }
  }

  .expandable-setting-value {
    &.pl-5 {
      padding-left: 2rem;
    }

    &.pb-2 {
      padding-bottom: 0.5rem !important;
    }

    .collapse > div > div:last-child {
      margin-bottom: 0.5rem;
    }

    .create {
      position: relative;
      margin: 0.2rem 3rem 0.2rem 4.2rem;

      .submit {
        position: absolute;
        right: 5px;
        top: 4px;
        color: $text-muted;
        padding: 5px;
        cursor: pointer;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: $black;
        }
      }
    }

    .top-level {
      display: flex;
      align-items: center;
      padding: 0.2rem;
    }
  }

  .container-variable {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    button {
      font-size: 0.8em;
    }

    .example-button {
      padding: 0.2rem;
      border: $border-width solid $text-muted;
      border-radius: $border-radius;
    }

    button {
      margin-top: 0 !important;
    }

    button {
      width: 100%;
      border: $border-width solid $border-color;

      &:disabled:not(.btn) {
        background-color: $gray-100;
      }

      &:focus {
        border-color: transparent;
      }
    }
  }
}

.d-none {
  display: none !important;
}
