.dropzone {
  background-color: $white;
  border: 2px dashed $gray-500;
  border-radius: $border-radius-lg;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;
  width: 100%;
  min-height: 20vh;

  color: $gray-500;

  &.hovered {
    border: 2px dashed $gray-600;
    color: $gray-600;
  }

  input {
    display: none;
  }
}