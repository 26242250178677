@mixin versioned-setting-color($color) {
  border-left-color: $color;

  /*  .heading {
    color: $color;
  }*/

  .type-badge {
    background-color: $color;
  }
}

@mixin versioned-setting($color) {
  > {
    .setting-card {
      @include versioned-setting-color($color);

      &:hover,
      &.hover-from-parent {
        @include versioned-setting-color(scale_color($color, $lightness: -20%));
      }
    }

    .setting-content {
      border-left: $border-width solid rgba($color, $alpha: 0.3);
    }
  }
}

$versionSettingsType: (
  "string": $green,
  "object": $purple,
  "int": $blue,
  "double": $cyan,
  "array": $orange,
  "boolean": $yellow,
  "null": $pink,
);

$versionSettingsTypeBackground: (
  "object": map_get($versionSettingsType, "object"),
  "array": map_get($versionSettingsType, "array"),
);

/*.setting-content {
  .collapse > div:last-child {
    margin-bottom: 0.5rem;
  }
}*/

.diff-setting {
  display: flex;
  position: relative;

  &.disabled {
    opacity: 0.6;
  }

  .setting:first-child {
    border-right: 2px solid $gray-500;

    .setting-card {
    }
  }

  .setting {
    width: 50%;
    @include versioned-setting($gray-600);

    @each $key, $color in $versionSettingsType {
      &.setting-#{$key} {
        @include versioned-setting($color);
      }
    }

    @each $key, $color in $versionSettingsTypeBackground {
      &.setting-#{$key} > .setting-content {
        background-color: lighten($color, 40%);
      }
    }

    @media screen and (max-width: 960px) {
      .setting-card {
        flex-direction: column;
      }
    }

    .setting-card {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      border-left: 2px solid;
      border-radius: $border-radius;
      transition: all 0.1s ease-in-out;
      height: 100%;
      min-height: 55px;
      border-top-left-radius: 0;
      margin-right: 15px;

      .heading-tag-area {
        min-width: 200px;

        .caret {
          font-size: 1.4em;
          transition: transform 0.1s ease-in-out;
          color: $gray-600;
          margin: 0rem 0.5rem 0 0.3rem;

          &.expanded {
            transform: rotate(90deg);
          }
        }
      }

      .heading-container,
      .tag-container {
        max-width: 300px;
        overflow: hidden;
        height: 20px;

        .marquee {
          transform: translateX(0%);
        }

        &:hover .marquee {
          animation: example1 10s linear infinite;
        }

        .non-editable {
          white-space: nowrap;
        }

        .heading,
        .tag {
          padding: 0;
          color: $gray-800;
          transition: all 0.2s ease-in-out;
          background-color: transparent;
          border-color: transparent;

          @keyframes example1 {
            0% {
              transform: translateX(0%);
            }
            50% {
              transform: translateX(-100%);
            }
            50.00000000000001% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(0%);
            }
          }
        }

        .heading {
          font-size: 0.85em;
          font-weight: 500;
        }

        .tag {
          font-size: 0.8em;
          color: $text-muted;
          font-weight: 300;

          &.only-show-on-hover {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }

          &.only-show-on-hover:hover,
          &.only-show-on-hover:focus {
            opacity: 100;
          }
        }
      }

      .badge-column {
        button {
          &:disabled {
            opacity: 1;
          }
        }

        .badge {
          color: $white;
          font-weight: lighter;
          padding: 0.5rem;
          width: 80px;
        }
      }
    }
  }

  .right-buttons {
    position: absolute;
    top: 10px;
    transition: all 0.3s ease-in-out;
    right: -190px;

    .more-options {
      background: none;
      border: none;
      color: $text-muted;
    }

    .diff-button {
      font-size: 12px;
      padding: 0 1px;
    }

    .accept-button,
    .reject-button {
      text-decoration: none;
    }

    .accept-button {
      color: $success;

      &:hover {
        color: darken($success, 10%);
      }
    }

    .reject-button {
      color: $gray-600;

      &:hover {
        color: darken($info, 10%);
      }
    }
  }

  .changed-text {
    position: absolute;
    top: 17px;
    transition: all 0.3s ease-in-out;
    right: -62px;

    &.ignore {
      color: $gray-600;
    }

    &.accepted {
      color: $success;
    }
  }
}

.atomic-value-container {
  max-width: 267px;
  overflow-wrap: break-word;
}

.setting-atomic-value {
  width: 100%;
  background-color: #f8f9fa;
  display: block;
  padding: 0.375rem 0.75rem;
  min-height: 33px;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.highlight {
  font-weight: 600;
  background: #ffff00;
  border-radius: 1px;
  padding: 2px 0;
}

.green-highlight {
  background: #a6ee91;
}

.red-highlight {
  background: #ea9191;
}
