body {
  background-color: #eee;
}

.outline-none {
  outline: none;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.transparent-button {
  cursor: pointer;
  color: $text-muted;
  transition: color, background-color 0.1s ease-in-out;
  margin: 0 2px;
  padding: map_get($spacers, 1) map_get($spacers, 3);
  outline: none;
  box-shadow: none;

  &.active {
    background-color: $gray-300;
    color: $gray-800;
  }

  &:not(:disabled):hover {
    background-color: $gray-300;
    color: $gray-800;
  }

  &.dropdown-toggle {
    &::before,
    &::after {
      display: none;
    }
  }
}

.hover-button {
  cursor: pointer;
  color: $text-muted;

  &:hover {
    color: $gray-700;
  }
}

.btn {
  box-shadow: none !important;
}

.ironsource-bridges {
  width: 100%;
  -webkit-appearance: none;
  position: relative;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &:disabled {
    opacity: 0.6;
    filter: brightness(0.8);
  }
}

.ironsource-bridges-div {
  position: relative;

  &:after {
    color: #444;
    content: " ▾";
    position: absolute;
    top: -8px;
    right: 15px;
    font-size: 2em;
    pointer-events: none;
  }
}

.background-color-blue {
  background-color: #3664b1;
  border-color: #3664b1;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #35529d;
    border-color: #35529d;
  }

  &:focus {
    background-color: #35529d;
    border-color: #35529d;
  }
}

.background-color-orange {
  background-color: orange;
  border-color: orange;
  color: $gray-800;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #ef9600;
    border-color: #ef9600;
    color: $gray-800;
  }

  &:focus {
    background-color: orange;
    border-color: orange;
    color: $gray-800;
  }
}

.text-color-blue {
  color: #3664b1;
}

.hidden {
  opacity: 0;
}

.import-textarea {
  min-height: 20vh;
}

.modal-dialog {
  max-width: 840px !important;
}

.import-option {
  min-width: 250px;
}

.export-textbox {
  margin-top: 0.5rem;
  max-width: 400px;
  min-height: 300px;
}

.padding-right-4rem {
  padding-right: 4rem !important;
}

@media screen and (max-width: 850px) {
  .import-option {
    min-width: 0px;
  }

  .import-options {
    flex-direction: column;
  }
}

.bs-popover-top {
  margin-bottom: 0 !important;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: none !important;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.expanded-log {
  background-color: #333333;
  cursor: initial;
  color: white;

  .jsondiffpatch-added,
  .jsondiffpatch-deleted {
    .jsondiffpatch-property-name {
      color: black;
    }
  }

  .jsondiffpatch-unchanged {
    color: white;
  }

  .jsondiffpatch-value {
    color: black;
  }
}

.parent-expanded-log {
  background-color: #e4e6ec;
}

.row-expansion-style {
  div {
    div {
      max-height: 1000px;
    }
  }
}

.jsondiffpatch-delta {
  width: 100%;
  max-width: 1000px;
  overflow: auto;
}

.change-type {
  span {
    display: inline-block;
    padding: 0.5em 0.8em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.6rem;
    font-weight: 600;
  }

  &.update {
    span {
      background-color: #fff9e5;
      color: #ad8b34;
    }
  }

  &.create {
    span {
      background-color: #e8fdec;
      color: #5ba56f;
    }
  }

  &.destroy {
    span {
      background-color: #fde6ec;
      color: #c9647b;
    }
  }
}

body {
  ///* System */
  //font-family: system, -apple-system, ".SFNSText-Regular", "San Francisco",
  //"Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;

  background-color: #eee;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.bp3-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
}

.chakra-modal__content {
  max-width: 800px !important;
}

.chakra-collapse {
  margin-top: 0 !important;
}

.makeStyles-root-1 {
  z-index: 1500 !important;
}
