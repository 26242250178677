.page-content {
  width: 100%;
  max-width: 1400px;
  min-width: 1100px;
  margin: 0 auto;
}

.both-settings-headers {
  position: sticky;
  top: 0;
  z-index: 10;
  max-width: 1400px;
  min-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 8rem 0rem 1rem;
  overflow: auto;

  .settings-heading:first-child {
    border-right: 2px solid $gray-500;
  }

  .settings-heading {
    box-sizing: border-box;
    .accept-button,
    .reject-button {
      text-decoration: none;

      &:disabled {
        opacity: 0.4;
      }
    }

    .accept-button {
      color: $success;

      &:hover {
        color: darken($success, 10%);
      }
    }

    .reject-button {
      color: $gray-600;

      &:hover {
        color: darken($info, 10%);
      }
    }
  }
}

.diff-tool-container {
  padding: 0 12rem 1rem 1rem;
  max-width: 1400px;
  min-width: 1100px;
  overflow: visible;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    .settings-heading {
      flex-direction: column;
    }
  }
}
