body {
  background-color: transparent;
  background-color: #eee;
}

.game-app {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px;

  .card-body {
    background-color: $white;
    border: #ced4da 1px solid;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .list-group {
    button {
      outline: none;
    }

    .last-updated {
      color: #adadad;
      font-weight: normal;
      transition: color 0.2s ease-in-out;
    }

    .background-color-orange {
      background-color: orange;
    }

    .text-color-orange {
      color: orange;
    }
  }

  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem;

    h1 {
      font-size: 1.5em;
    }

    h2 {
      font-size: 1em;

      span {
        font-weight: lighter;
      }
    }

    h3 {
      font-size: 1em;
      font-weight: lighter;
    }
  }
}
