// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900,
) !default;

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900,
) !default;

$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900,
) !default;

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900,
) !default;

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900,
) !default;

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900,
) !default;

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900,
) !default;

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900,
) !default;

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900,
) !default;

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900,
) !default;
// fusv-enable

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-container-classes: true !default;
$enable-cssgrid: false !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(
  180deg,
  rgba($white, 0.15),
  rgba($white, 0)
) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$border-style: solid !default;
$border-color: $gray-300 !default;
$border-color-translucent: rgba($black, 0.175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.375rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;
// scss-docs-end box-shadow-variables

$component-active-color: $white !default;
$component-active-bg: $primary !default;

// scss-docs-start caret-variables
$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;
// scss-docs-end caret-variables

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$prefix}font-sans-serif) !default;
$font-family-code: var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

$display-font-family: null !default;
$display-font-style: null !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 0.875em !default;

$sub-sup-font-size: 0.75em !default;

$text-muted: $gray-600 !default;

$initialism-font-size: $small-font-size !default;

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;

// fusv-disable
$hr-bg-color: null !default; // Deprecated in v5.2.0
$hr-height: null !default; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color: null !default; // Allows for inherited colors
$hr-border-width: $border-width !default;
$hr-opacity: 0.25 !default;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$dt-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-padding: 0.1875em !default;
$mark-bg: $yellow-100 !default;
// scss-docs-end type-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: var(--#{$prefix}body-color) !default;
$table-bg: transparent !default;
$table-accent-bg: transparent !default;

$table-th-font-weight: null !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: var(--#{$prefix}border-color) !default;

$table-striped-order: odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentcolor !default;

$table-caption-color: $text-muted !default;

$table-bg-scale: -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  "primary": shift-color($primary, $table-bg-scale),
  "secondary": shift-color($secondary, $table-bg-scale),
  "success": shift-color($success, $table-bg-scale),
  "info": shift-color($info, $table-bg-scale),
  "warning": shift-color($warning, $table-bg-scale),
  "danger": shift-color($danger, $table-bg-scale),
  "light": $light,
  "dark": $dark,
) !default;
// scss-docs-end table-loop

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;

$input-btn-border-width: $border-width !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-color: var(--#{$prefix}link-color) !default;
$btn-link-hover-color: var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color: $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 20% !default;
$btn-hover-border-tint-amount: 10% !default;
$btn-active-bg-shade-amount: 20% !default;
$btn-active-bg-tint-amount: 20% !default;
$btn-active-border-shade-amount: 25% !default;
$btn-active-border-tint-amount: 10% !default;
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: null !default;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg: $body-bg !default;
$input-disabled-color: null !default;
$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: null !default;

$input-color: $body-color !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: $box-shadow-inset !default;

$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-lg !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add(
  $input-line-height * 1em,
  $input-padding-y * 2
) !default;
$input-height-inner-half: add(
  $input-line-height * 0.5em,
  $input-padding-y
) !default;
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  $input-padding-y * 0.5
) !default;

$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
) !default;
$input-height-sm: add(
  $input-line-height * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
) !default;
$input-height-lg: add(
  $input-line-height * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
) !default;
