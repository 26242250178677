.popover-tooltip {
  max-height: 600px;
  overflow: auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.setting-id-value {
  border-left: 2px solid lightgray;
  background-color: rgba(62, 62, 62, 0.4);
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: break-word;
  flex-wrap: wrap;

  .setting-id {
    margin-top: 1rem;
    .badge {
      margin: 0 0.2rem;
    }

    .btn-group {
      .btn {
        font-size: 0.7rem;
        padding: 0 0.4rem;
      }
    }

    .value {
      font-weight: 600;
      padding: 0 0.4rem;
    }
  }

  .setting-value-not-exist {
    color: gray;
  }
}

.setting-badge-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0;
}

.truncated {
  position: relative;
  max-height: 200px;
  overflow: hidden;

  .read-more {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    background-image: linear-gradient(to bottom, transparent, black);
  }
}
