.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.text-left {
  text-align: left;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.w-100 {
  width: 100%;
}
